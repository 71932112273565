<template>
  <List el="xsm" style="padding: 32px">
    <!-- <Text el="h2">{{ $route.params.id }}</Text> -->
    <List el="column-between" class="v-center">
      <Text el="h3" v-if="!editRecording">Upload Recording</Text>
      <Text el="h3" v-else>Edit Recording</Text>
      <Icon name="close" @click="close" />
    </List>

    <input
      type="file"
      @change="previewFiles"
      multiple
      ref="fileInput"
      v-show="false"
    />

    <Accordion v-if="!editRecording">
      <List el="column-between xsm">
        <List el="column xsm" style="align-items: center">
          <Icon name="upload_file" @click.stop="$refs.fileInput.click()" />
          <Text el="p bold"
            >{{ attachedFilesLen }}
            {{ attachedFilesLen === 1 ? "File" : "Files" }}</Text
          >
        </List>
        <span class="material-icons"></span>
      </List>
      <List el="xsm">
        <Text el="p" v-for="item in recording.attachedFiles.value" :key="item">
          {{ item.name }}
        </Text>
      </List>
    </Accordion>

    <div el="input-list">
      <label el="input-label">
        <div el="sm-txt label">Date</div>
        <input
          type="date"
          name="input"
          placeholder="Date (Format: MM/DD/YYYY)"
          v-model="recording.date"
          el
        />
      </label>
      <label el="input">
        <input
          type="text"
          name="input"
          placeholder="Title"
          v-model="recording.label"
          el
        />
      </label>
      <label el="input">
        <div el="sm-txt label" style="margin-bottom: var(--space-xxxsm)">
          Label
        </div>
        <textarea
          name="textarea1"
          placeholder="Description..."
          cols="30"
          rows="10"
          v-model="recording.description"
          el
        ></textarea>
      </label>
    </div>
    <Text>Tags</Text>
    <div class="tags">
      <Text
        v-for="item in recording.tagList"
        :key="item"
        @click="toggleTag(item)"
        :class="{ active: recording.tags.includes(item) }"
        >{{ item }}</Text
      >
    </div>
    <Text el="h5" v-if="Object.keys(uploads.list).length > 0"
      >Upload Progress</Text
    >
    <Text el="p" v-for="item in uploads.list" :key="item"
      ><b>{{ Math.round(item.progress) }}%</b> - {{ item.name }}</Text
    >
    <!-- <h4 el>Content</h4> -->

    <List el="column-stretch xsm" style="margin-top: 32px">
      <Btn el="2ry" @click="saveRecordingDraft" v-if="validInput && !editRecording"
        >Save Draft</Btn
      >
      <Btn el="2ry" disabled v-else>Save Draft</Btn>
      <Btn @click="saveRecording" v-if="validInput && !editRecording">Save</Btn>
      <Btn @click="saveRecording" v-else-if="validEditInput && editRecording">Save</Btn>
      <Btn disabled v-else>Save</Btn>
    </List>
  </List>
</template>
<script>
let forEach = require("lodash/forEach");
let map = require("lodash/map");
import { ref as vueRef, reactive } from "vue";
import router from "../router";
import Icon from "@/components/btn/Icon.vue";
import { db, auth } from "@/firebase";
import { addDoc, collection, doc, getDoc, setDoc} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  //   getDownloadURL,
} from "firebase/storage";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      groups: this.$store.state.main.groups,
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      main: this.$store.state.main.layout.main,
      section: this.$store.state.main.layout.section,
      module: null,
      recording: {
        label: "",
        description: "",
        date: "",
        tags: [],
        tagList: [],
        // thumbnail: "URL",
        permissions: [],
        attachedFiles: vueRef([]),
        status: "publish",
      },
      files: null,
      uploads: {
        list: reactive({}),
      },
      editRecording: false,
    };
  },
  methods: {
    toggleTag(item) {
      let tags = this.recording.tags;
      if (tags.includes(item)) {
        let i = tags.indexOf(item);
        if (i > -1) {
          tags.splice(i, 1);
        }
      } else {
        tags.push(item);
      }
    },
    close() {
      this.closeSection();
      router.push("/dashboard/recordings");
    },
    closeSection() {
      this.section.collapse = true;
    },
    previewFiles(event) {
      let x = this;
      const files = event.target.files;
      x.files = files;

      x.recording.attachedFiles.value = map(files, (item) => {
        return {
          name: item.name,
          size: item.size,
          type: item.type,
        };
      });

      // forEach(files, (item) => {
      //   x.recording.attachedFiles.push({
      //     name: item.name,
      //     size: item.size,
      //     type: item.type,
      //   });
      // });
    },
    uploadFiles(path) {
      let x = this;
      const storage = getStorage();

      forEach(x.files, (item) => {
        const storageRef = ref(storage, path + item.name);
        const uploadTask = uploadBytesResumable(storageRef, item);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            x.uploads.list[item.name] = {
              name: item.name,
              size: item.size,
              type: item.type,
              bytesTransferred: snapshot.bytesTransferred,
              totalBytes: snapshot.totalBytes,
              progress: progress,
              state: snapshot.state,
            };

            // console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                // console.log("Upload is paused");
                break;
              case "running":
                // console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            console.log(error);
            alert(error);
          },
          () => {
            // Handle successful uploads on complete
            // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            //   console.log("File available at", downloadURL);
            // });
          }
        );
      });
    },
    async saveRecording() {
      var x = this;
      let d = new Date(`${x.recording.date} 00:00:00`),
        now = Date.now();
      let data = {
        label: x.recording.label,
        description: x.recording.description,
        date: d.toLocaleDateString("en-CA"),
        tags: x.recording.tags,
        // thumbnail: x.recording.thumbnail,
        permissions: x.recording.permissions,
        uploadedOn: now,
        uploadedBy: {
          uid: auth.currentUser.uid,
          displayName: auth.currentUser.displayName,
        },
        attachedFiles: x.recording.attachedFiles.value,
        status: x.recording.status,
        // id: "vid1",
      };

      if(this.editRecording && this.$route.params.id){
        let recordingID = this.$route.params.id;
        const docRef = doc(db, 'recordings', recordingID);
        await setDoc(docRef, {
          label: data.label,
          description: data.description,
          date: data.date,
          tags: data.tags,
          permissions: data.permissions,
          updatedBy: {
            uid: auth.currentUser.uid,
            displayName: auth.currentUser.displayName,
          },
          lastUpdated: now,
        }, { merge: true });
      }else{
        const docRef = await addDoc(collection(db, "recordings"), data);

        if (this.attachedFilesLen > 0) {
          this.resetUpload();
          this.uploadFiles("recordings/" + docRef.id + "/");
        }
      }
      // console.log(docRef.id);
      // data.id = docRef.id;
      // x.videosList[docRef.id] = data;
      
      //   this.resetUpload();
      //   this.closeSection();
    },
    saveRecordingDraft() {
      this.recording.status = "draft";
      this.saveRecording();
      //   this.closeSection();
    },
    resetUpload() {
      let r = this.recording;
      r.label = "";
      r.description = "";
      r.date = "";
      r.tags = ["SDLC", "programming"];
      r.permissions = [];
      r.attachedFiles.value = [];
      r.status = "publish";
      let d = new Date();
      r.date = d.toLocaleDateString("en-CA");
    },
    async getRecording() {
      let recordingID = this.$route.params.id;
      let r = this.recording;
      if (recordingID != null) {
        const docRef = doc(db, "recordings", recordingID);
        const docSnap = await getDoc(docRef);
        let data = docSnap.data();
        data.id = docSnap.id;
        // this.recording = data;
        r.id = data.id;
        r.label = data.label;
        r.description = data.description;
        r.tags = data.tags;
      }
    },
  },
  mounted() {
    let d = new Date();
    this.recording.date = d.toLocaleDateString("en-CA");
    let route = this.$route.path;
    if(route.search('edit') >= 0){
      this.editRecording = true;
      this.getRecording();
    }
    this.recording.tags = ["SDLC", this.groups.active];
    this.recording.tagList = ["SDLC", "programming", "automation", ...this.groups.list];
  },
  watch: {
    "groups.active": function (val) {
      this.recording.tags = ["SDLC", val];
    },
  },
  computed: {
    attachedFilesLen() {
      if (this.recording.attachedFiles.value != null) {
        return this.recording.attachedFiles.value.length;
      } else {
        return 0;
      }
    },
    validInput() {
      let r = this.recording;
      if (r.label != "" && this.attachedFilesLen > 0 && r.tags.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    validEditInput() {
      let r = this.recording;
      if (r.label != "" && r.tags.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags > * {
  margin: 3px;
  padding: 3px 13px;
  /* border: 1px solid var(--color-7); */
  justify-self: flex-start;
  border-radius: 100px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
  user-select: none;
  color: var(--color-65);
  -webkit-text-fill-color: currentColor;
}
.tags > .active {
  color: var(--color-white);
  background-color: var(--color-cloud);
}
</style>
